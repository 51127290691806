import React, { useState, useEffect, useRef } from 'react';
import {
  ChakraProvider,
  extendTheme,
  Box,
  Stack,
  Text,
  Select,
  useToast,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Alert,
  SimpleGrid,
  VStack,
  Slider,
  Toast,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Spinner,
  Button,
  Input,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Center,
  InputGroup,
  InputRightElement,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { CgLink } from 'react-icons/cg';
import UploadComponent from './UploadComponent';
import TagPremio from './TagPremio';
import useAxios from 'axios-hooks';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { AiFillEye } from 'react-icons/ai';
import PreviewWheel from './PreviewWheel';
import { FaPowerOff } from 'react-icons/fa';
import Footer from './Footer';
import Navbar from './Navbar';
import 'intro.js/introjs.css';
import { Steps, Hints } from 'intro.js-react';

import 'animate.css';

export const UsersIcon: FunctionComponent<Props> = ({ ...props }) => (
  <Icon {...props} fontSize={'19px'} verticalAlign="center" viewBox="0 0 25 15">
    <BsPlusCircleFill />
  </Icon>
);

export const JustAPanel = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [pais, setPais] = useState();
  const [getLink, setGetLink] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (sessionStorage.precio === undefined) sessionStorage.precio = '-';
    if (parseInt(sessionStorage.getLink) === 1) setGetLink(true);
    document.body.style.backgroundImage = "url('fondo_kawaii_putiruleta4.jpg')";
    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then(response => {
        sessionStorage.pais = response.data.country_code;
        setPais(response.data.country_code);
      })
      .catch(error => {
        ////console.log(error);
      });
  }, []);

  const [{ data: getData, loading: getLoading, error: getError }, refetch] =
    useAxios({
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url:
        'https://server-mb9m.onrender.com/getPremios/' +
        (sessionStorage.ruletahash !== undefined
          ? sessionStorage.ruletahash
          : 'exit'),
      useCache: false,
    });

  useEffect(() => {
    if (
      getData !== undefined &&
      getData.resultado !== 'exit' &&
      !getData.resultado === []
    ) {
      var arrHashes = getData.resultado.map(x => x.hash);
      ////console.log(hashes);
      setHashes(arrHashes);
      sessionStorage.premioshash = null;
      sessionStorage.premioshash = arrHashes.join(',');
      if (arrHashes.length > 0) setIsThereHistory(true);
    }
  }, [getData]);

  const [
    {
      data: postURLPaypal,
      loading: postURLPaypalLoading,
      error: postURLPaypalError,
    },
    executeURLPaypal,
  ] = useAxios(
    {
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url: 'https://server-mb9m.onrender.com/payconfig/',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`, // Incluir el token en el encabezado de autorización
      },
    },
    { manual: true }
  );

  const [
    { data: postData, loading: postLoading, error: postError },
    executePost,
  ] = useAxios(
    /*   {
      url: 'https://server-mb9m.onrender.com/postPremio',
      method: 'POST',
    },
    */
    {
      url: 'https://server-mb9m.onrender.com/postPremioPP',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`, // Incluir el token en el encabezado de autorización
      },
    },
    { manual: true }
  );

  const seleccionarRef = useRef(null);
  const minInput = useRef(null);
  const customInput = useRef(null);
  const precioInput = useRef(null);
  const tipoInput = useRef(null);
  const sliderInput = useRef(null);

  const theme = extendTheme({
    colors: {
      rosa: {
        50: '#FFE6F1',
        100: '#FEB8D8',
        200: '#FE8BBE',
        300: '#FD5DA5',
        400: '#FD308C',
        500: '#FD0272',
        600: '#CA025B',
        700: '#980145',
        800: '#65012E',
        900: '#330017',
      },
    },
    components: {
      Button: {
        marginTop: '5px',
        baseStyle: {
          color: 'black.500',
        },
      },
      Input: {
        variants: {
          filled: {
            field: {
              bg: 'red.100',
            },
          },
        },
      },
    },

    styles: {
      global: (props: StyleFunctionProps) => ({
        colors: {
          text: 'white',
          rosa: {
            50: '#FFE6F1',
            100: '#FEB8D8',
            200: '#FE8BBE',
            300: '#FD5DA5',
            400: '#FD308C',
            500: '#FD0272',
            600: '#CA025B',
            700: '#980145',
            800: '#65012E',
            900: '#330017',
          },
        },
        body: {
          fontFamily: "'Poppins', sans-serif;",
        },
      }),
    },
  });
  const [sliderValue, setSliderValue] = useState(50);
  const [isMinutos, setIsMinutos] = useState(false);
  const [isSomethingSelected, setIsSomethingSelected] = useState(false);
  const [isTipo, setIsTipo] = useState(false);
  const [isCustomized, setIsCustomized] = useState(false);
  const [minutos, setMinutos] = useState();
  const [personalizedPremio, setPersonalizedPremio] = useState();
  const [ruletaHash, setRuletaHash] = useState(sessionStorage.ruletahash);
  const [filer, setFiler] = useState(false);
  const [seleccionado, setSeleccionado] = useState(null);
  const [unanueva, setUnaNueva] = useState(false);
  const [isToggleSlider, setIsToggleSlider] = useState(true);
  const [isThereHistory, setIsThereHistory] = useState(false);
  const [hashes, setHashes] = useState([]);
  const [precio, setPrecio] = useState();
  const [paypal, setPaypal] = useState();
  const [emailInteresada, setEmailInteresada] = useState();
  const [isThereAccessToken, setIsThereAccessToken] = useState(false);
  const [token, setToken] = useState(sessionStorage.token);
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [match, setMatch] = useState(false);
  const [bluerd, setBlured] = useState(false);
  const handleInput2Blur = () => {
    setBlured(true);
    setMatch(input1 === input2);
  };

  const handleInput1Change = event => {
    setInput1(event.target.value);
  };

  const handleInput2Change = event => {
    setInput2(event.target.value);
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    if (input1 === input2) {
      console.log('Datos enviados:', input1, input2);
    } else {
      // Los campos no coinciden, puedes mostrar un mensaje de error o realizar otra acción necesaria.
      console.log('Los campos no coinciden');
    }
  };
  useEffect(() => {
    setMatch(input1 === input2);
  }, [input1, input2]);

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  };

  useEffect(() => {
    if (sessionStorage.token != null) {
      setToken(sessionStorage.token);
    } else {
      setIsThereHistory(false);
      setToken(null);
    }
    if (sessionStorage.ruletahash) {
      if (
        getData !== undefined &&
        getData.resultado !== 'exit' &&
        !getData.resultado === []
      ) {
        var arrHashes = getData.resultado.map(x => x.hash);
        setHashes(arrHashes);
        sessionStorage.premioshash = arrHashes.join(',');
        if (arrHashes.length > 0) setIsThereHistory(true);
      } else {
        refetch().then(res => {
          var arrHashes = res.data.resultado.map(x => x.hash);
          setHashes(arrHashes);
          sessionStorage.premioshash = arrHashes.join(',');
          setIsThereHistory(true);
        });
      }
    }
  }, []);

  const createHash = () => {
    const hash = Array.from(Array(6), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join('');

    return hash;
  };

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const handleRelogin = () => {};

  const handleContrasena = e => {
    setPassword(e);
  };
  const handleUser = e => {
    setUsername(e);
  };

  const handleLogin = async e => {
    setUnaNueva(true);
    console.log(username, password);
    try {
      axios
        .post('https://server-mb9m.onrender.com/login', {
          username: username,
          password: password,
        })
        .then(response => {
          const { token, user } = response.data;
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('user_id', user.id);
          sessionStorage.setItem('username', user.username);
          setToken(token);
          sessionStorage.ruletahash = createHash();
          setRuletaHash(sessionStorage.ruletahash);
        })
        .catch(error => {
          setUnaNueva(false);
          // Maneja el error si ocurre
        })
        .finally(() => {
          setUnaNueva(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegistrarse = () => {};

  const handleDesvincular = () => {
    NiceModal.show('infomodal', { alerta: 1 });
  };

  const handleMostrarLink = () => {
    NiceModal.show('infomodal', {
      link_inv:
        'https://' + window.location.hostname + '/' + sessionStorage.ruletahash,
    });
  };

  const handleInputMinutos = e => {
    if (Number(e)) {
      setMinutos(e);
    } else {
      setMinutos('');
    }
  };

  const handleInputPersonalizado = e => {
    const regex =
      /(Foto|foto|Fotos|fotos|video|Videos|videos|Video|Imagen|imagen|Imagenes|imagenes|pic|Pic|pics|Pics)/;

    if (regex.test(e)) {
      toast({
        title: 'Error',
        description:
          'Debes Seleccionar 🎬📷 Foto y/o Video para premios audiovisuales.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
      customInput.current.value = null;
    } else {
      setPersonalizedPremio(e);
    }
  };

  var intervalo = null;
  const handleSiguiente = () => {
    let data = {};
    var ruleta_hash = sessionStorage.ruletahash;
    if (seleccionado !== null) {
      //////console.log('que onda', seleccionado);
      switch (seleccionado) {
        case 'sexting':
          if (minutos !== '' && ruleta_hash !== undefined) {
            data.hash = createHash();
            data.ruleta_hash = ruleta_hash;
            data.titulo =
              minutos !== undefined
                ? 'Sexting 💌 ' + minutos + ' min.'
                : 'Sexting 💌 10 min.';
            data.min = minutos !== undefined ? minutos : '10';
            data.tipo = 'texto';
            data.probabilidad = sliderValue;
            sessionStorage.titulos =
              sessionStorage.titulos === undefined
                ? seleccionado
                : sessionStorage.titulos + ',sexting';
          }
          minInput.current.value = null;
          break;
        case 'zing':
          if (ruleta_hash !== undefined) {
            data.hash = createHash();
            data.ruleta_hash = ruleta_hash;
            data.titulo = 'Zing';
            data.min = minutos;
            data.tipo = 'texto';
            data.probabilidad = sliderValue;
            sessionStorage.titulos =
              sessionStorage.titulos === undefined
                ? seleccionado
                : sessionStorage.titulos + ',Zing';
          }
          break;
        case 'dickrate':
          if (ruleta_hash !== undefined) {
            data.hash = createHash();
            data.ruleta_hash = ruleta_hash;
            data.titulo = 'DickRate';
            data.min = minutos !== undefined ? minutos : '10';
            data.tipo = 'dickrate';
            data.probabilidad = sliderValue;
            sessionStorage.titulos =
              sessionStorage.titulos === undefined
                ? seleccionado
                : sessionStorage.titulos + ',DickRate';
          }
          break;
        case 'video-chat':
          if (minutos !== '' && ruleta_hash !== undefined) {
            data.hash = createHash();
            data.ruleta_hash = ruleta_hash;
            data.titulo =
              minutos !== undefined
                ? 'Video Chat 📲 ' + minutos + ' min.'
                : 'Video Chat 📲 10 min.';
            data.min = minutos !== undefined ? minutos : '10';
            data.tipo = 'video-audio';
            data.probabilidad = sliderValue;
            sessionStorage.titulos =
              sessionStorage.titulos === undefined
                ? seleccionado
                : sessionStorage.titulos + ', VideoChat';
          }
          minInput.current.value = null;
          break;
        case 'fotovideo':
          if (sessionStorage.subiendo) {
            setUnaNueva(true);
            //Deshabiilito boton mientras existe la variable local, se va cuando onAllComplete de uploader
            intervalo = setInterval(() => {
              if (sessionStorage.subiendo) setUnaNueva(true);
              else setUnaNueva(false);
            }, 500);
            return;
          } else {
            clearInterval(intervalo);
            if (sessionStorage.objetomedia) {
              data.hash = createHash();
              data.ruleta_hash = ruleta_hash;
              data.titulo = sessionStorage.titulo;
              data.min = '';
              data.tipo = 'video-audio';
              data.objeto_media = sessionStorage.objetomedia;
              data.probabilidad = sliderValue;
              sessionStorage.removeItem('objetomedia');
              sessionStorage.titulos =
                sessionStorage.titulos + ', ' + sessionStorage.titulo;

              setTimeout(() => {
                seleccionarRef.current.selectedIndex = 0;
              }, 2000);
            }
          }
          break;
        case 'user-choice':
          if (ruleta_hash !== undefined) {
            data.hash = createHash();
            data.ruleta_hash = ruleta_hash;
            data.titulo = personalizedPremio;
            data.min = minutos;
            data.tipo = 'user-choice';
            data.probabilidad = sliderValue;
            sessionStorage.titulos =
              sessionStorage.titulos === undefined
                ? seleccionado
                : sessionStorage.titulos +
                  ', ' +
                  personalizedPremio.substring(0, 5) +
                  '...';
          }
          customInput.current.value = null;
          break;
        default:
          break;
      }
      data.user_id = sessionStorage.user_id;
      data.pais = sessionStorage.pais;
      executePost({ data }).then(res => {
        setIsToggleSlider(false);
        setTimeout(() => {
          setIsToggleSlider(true);
        }, 1000);
        setSliderValue(50);
        seleccionarRef.current.selectedIndex = 0;
        setIsMinutos(false);
        setIsSomethingSelected(false);
        setIsCustomized(false);
        setIsTipo(false);
        setFiler(false);
        setIsThereHistory(true);
        //si requiere login popup "Debes conectar tu mercadopago apra editar esta ruleta"

        refetch().then(res => {
          if (getData !== undefined) {
            var arrHashes = res.data.resultado.map(x => x.hash);
            setHashes(arrHashes);

            sessionStorage.premioshash = arrHashes.join(',');
            if (arrHashes.length > 0) {
              setIsThereHistory(true);
            }
          }
        });
      });
      setSeleccionado(null);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = e => {
    switch (e) {
      case 'default':
        setIsMinutos(false);
        setFiler(false);
        setIsTipo(false);
        setIsCustomized(false);
        setIsSomethingSelected(false);
        break;
      case 'sexting':
        setSeleccionado('sexting');
        setIsMinutos(true);
        setFiler(false);
        setIsTipo(false);
        setIsCustomized(false);
        setIsSomethingSelected(true);
        break;
      case 'zing':
        setSeleccionado('zing');
        setIsMinutos(false);
        setFiler(false);
        setIsCustomized(false);
        setIsTipo(false);
        setIsSomethingSelected(true);
        break;
      case 'dickrate':
        setSeleccionado('dickrate');
        setIsMinutos(false);
        setFiler(false);
        setIsTipo(true);
        setIsCustomized(false);
        setIsSomethingSelected(true);
        break;
      case 'video-chat':
        setSeleccionado('video-chat');
        setIsMinutos(true);
        setIsTipo(false);
        setFiler(false);
        setIsCustomized(false);
        setIsSomethingSelected(true);
        break;
      case 'fotovideo':
        setSeleccionado('fotovideo');
        setIsMinutos(false);
        setIsTipo(false);
        setFiler(true);
        setIsCustomized(false);
        setIsSomethingSelected(true);
        break;
      case 'user-choice':
        setSeleccionado('user-choice');
        setIsMinutos(false);
        setIsCustomized(true);
        setIsTipo(false);
        setFiler(false);
        setIsSomethingSelected(true);
        break;
      default:
        break;
    }
  };

  var data = {};
  const handleEnviadata = e => {
    setIsOpen(false);
    setUnaNueva(true);
    let precioParaStorage = 0;
    let precioInicialStorage = parseFloat(precio);
    let comisionStorage = parseFloat(precio) * 0.2;
    precioParaStorage = (precioInicialStorage + comisionStorage).toFixed(0);
    console.log((precioInicialStorage + comisionStorage).toFixed(0));
    sessionStorage.precio =
      parseFloat(precioParaStorage) +
      parseFloat(0.95) +
      '|' +
      pais +
      '|' +
      sessionStorage.ruletahash;
    //todo hacer link de pago
    data.ruleta = sessionStorage.ruletahash;
    data.precio = precio;
    data.pais = pais;
    data.user_id = sessionStorage.user_id;
    data.success_url = 'https://putiruleta.com/' + sessionStorage.ruletahash;
    console.log(data);
    executeURLPaypal({ data }).then(res => {
      console.log(res);
      toast({
        title: 'Precio establecido',
        description: 'USD ' + precio,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      setUnaNueva(false);
      // window.location.href = res.data;
      //navigate('/preview/' + ruletaHash + '/' + token_check);
    });
  };

  const [recargar, setRecargar] = useState();

  function handleChildEvent() {
    setRecargar(false);
    setTimeout(() => {
      setRecargar(true);
    }, 2000);
  }

  const steps = [
    {
      element: '.pruebita',
      intro: 'En construccion... disculpe las molestias',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.selector2',
      intro: 'test 2',
    },
    {
      element: '.selector3',
      intro: 'test 3',
    },
  ];

  const crearcuentaRef = useRef(null);
  const acordionItemCuenta = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const handleScrollDown = () => {
    crearcuentaRef.current.scrollIntoView({
      behavior: 'smooth',
      speed: 'slow',
    });
    setTimeout(() => {
      setIsFocused(true);
      acordionItemCuenta.current.click();
    }, 500);
  };

  const [stepsEnabled, setStepsEnabled] = useState(false);

  const handleHelpClick = () => {
    setStepsEnabled(true);
  };

  const onExit = () => {
    ////console.log('exit');
  };

  const handleNueva = () => {
    NiceModal.show('infomodal', { alerta: 0 });
  };

  const handlePaypalChange = e => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(e)) {
      setPaypal(e);
    }
  };
  const handlePrecioChange = e => {
    const inputValue = e.target.value;
    // Verificar si el valor es un número válido
    if (!isNaN(inputValue)) {
      setPrecio(inputValue);
    }
  };
  const isSmall = useMediaQuery('(max-width: 400px)');
  const isMedium = useMediaQuery('(max-width: 800px)');

  if (getLoading)
    return (
      <ChakraProvider theme={theme}>
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner
            thickness="24px"
            speed="0.65s"
            emptyColor="#a5e0cf"
            color="#f5a7d5"
            style={{
              position: 'relative',
              zIndex: 7,
              width: '151px',
              height: '151px',
              margin: '37% auto',
              transform: 'translate( -50%, -50% )',
            }}
          />
        </Box>
      </ChakraProvider>
    );
  if (getError) return <p>Error!</p>;
  //<Steps enabled={stepsEnabled} steps={steps} onExit={onExit} />
  return (
    <ChakraProvider theme={theme}>
      <Navbar onClick={handleHelpClick} />
      <Box
        w={isSmall[0] ? '340px' : isMedium[0] ? '400px' : '450px'}
        backgroundColor="#ffffff"
        mt="1rem"
        mb="1rem"
        mx="auto"
        style={{
          border: '#ccc 1px solid',
          borderRadius: '21px',
        }}
      >
        <Stack
          spacing={2}
          minHeight="20%"
          padding="31px 11px"
          w={isSmall[0] ? '320px' : isMedium[0] ? '380px' : '420px'}
        >
          <VStack>
            <Text
              textAlign="center"
              fontSize="2xl"
              mt="auto"
              mb="auto"
              fontWeight="700"
            >
              Configura tu Ruleta 🔧
            </Text>
            {sessionStorage.ruletahash != null && (
              <small>{'(' + sessionStorage.ruletahash + ')'}</small>
            )}
          </VStack>
          {token &&
          isThereHistory &&
          sessionStorage.premioshash?.split(',').length > 0 &&
          sessionStorage.premioshash !== '' ? (
            <>
              <Box>
                {' '}
                <Text>Premios:</Text>{' '}
              </Box>
              <Box>
                <PreviewWheel recargar={recargar} />
              </Box>
              <SimpleGrid
                columns={isSmall[0] ? 2 : 3}
                spacing={1}
                w={isSmall[0] ? '320px' : isMedium[0] ? '380px' : '420px'}
              >
                {getData.resultado &&
                  getData.resultado.map(x => {
                    return (
                      <Center key={x.hash}>
                        <TagPremio
                          key={x.hash}
                          hash={x.hash}
                          titulo={x.titulo}
                          onEvent={handleChildEvent}
                        />
                      </Center>
                    );
                  })}
              </SimpleGrid>
            </>
          ) : (
            <>
              <Box>
                <PreviewWheel recargar={recargar} />
              </Box>
              <Box textAlign="center">
                {token != null ? (
                  <Text margin="15px 0">
                    Sin premios aun, define tus premios
                  </Text>
                ) : (
                  <>
                    <Text margin="15px 0" style={{ fontSize: '34px' }}>
                      ¡Bienvenid@!
                    </Text>
                    <Text margin="15px 0">Inicia sesion para comenzar.</Text>
                    <Box>
                      <Input
                        placeholder={'Usuario'}
                        border="1px solid gray" // Grosor del borde por defecto
                        _focus={{
                          border: '3px solid #def3ec', // Grosor del borde al hacer clic
                          color: 'black', // Color del texto al hacer clic
                        }}
                        mt="9px"
                        onChange={e => handleUser(e.target.value)}
                      />
                    </Box>
                    <Box>
                      <Input
                        placeholder={'Contraseña'}
                        border="1px solid gray" // Grosor del borde por defecto
                        _focus={{
                          border: '3px solid #def3ec', // Grosor del borde al hacer clic
                          color: 'black', // Color del texto al hacer clic
                        }}
                        mt="9px"
                        onChange={e => handleContrasena(e.target.value)}
                      />
                    </Box>
                    <Button
                      size="lg"
                      minWidth="100%"
                      marginTop="39px"
                      colorScheme="blue"
                      isLoading={unanueva ? true : postLoading}
                      onClick={() => {
                        handleLogin();
                      }}
                    >
                      {'Entrar'}
                    </Button>
                    <Text
                      onClick={() => handleScrollDown()}
                      style={{ cursor: 'pointer', paddingTop: '15px' }}
                      className={'animate shake'}
                    >
                      No tienes cuenta? Crear cuenta aqui.
                    </Text>
                  </>
                )}
              </Box>
            </>
          )}
        </Stack>
        <Stack spacing={2} minHeight="40%" padding="31px 11px">
          {token != null ? (
            <Flex mb="auto" flexDirection="column" mt="0">
              <Select
                className={'pruebita'}
                icon={<ChevronDownIcon />}
                variant="outline"
                size="md"
                fontSize="27px"
                minWidth="277px"
                mr="auto"
                ml="auto"
                mt="auto"
                mb="auto"
                ref={seleccionarRef}
                onChange={e => {
                  handleChange(e.target.value);
                }}
              >
                <option value="default">Seleccionar...</option>
                <option value="sexting">Sexting</option>
                <option value="zing">Zing</option>
                <option value="video-chat">Video Chat</option>
                <option value="dickrate">Dickrate</option>

                <option value="fotovideo">🎬📷 Foto y/o Video</option>
                <option value="user-choice">Personalizar...</option>
              </Select>

              {isMinutos && (
                <Box>
                  <Text mt="9px">⏱ Minutos</Text>
                  <Input
                    border="1px solid gray" // Grosor del borde por defecto
                    _focus={{
                      border: '3px solid #def3ec', // Grosor del borde al hacer clic
                      color: 'black', // Color del texto al hacer clic
                    }}
                    ref={minInput}
                    mt="9px"
                    onChange={e => handleInputMinutos(e.target.value)}
                  />
                </Box>
              )}

              {isCustomized && (
                <Box>
                  <Text mt="9px">Ingresar Premio Personalizado</Text>
                  <Input
                    border="1px solid gray" // Grosor del borde por defecto
                    _focus={{
                      border: '3px solid #def3ec', // Grosor del borde al hacer clic
                      color: 'black', // Color del texto al hacer clic
                    }}
                    ref={customInput}
                    mt="9px"
                    onChange={e => handleInputPersonalizado(e.target.value)}
                  />
                </Box>
              )}
              {filer && (
                <Box>
                  <UploadComponent />
                </Box>
              )}
              <Box>
                {isSomethingSelected && (
                  <Accordion
                    allowToggle
                    paddingTop="31px"
                    className={'Pruebita2'}
                  >
                    <AccordionItem>
                      <h2>
                        <AccordionButton
                          _expanded={{ bg: '#def3ec', color: 'black' }}
                        >
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            style={{ color: '#9f9c9c' }}
                          >
                            ⚙ Probabilidades
                          </Box>
                          <AccordionIcon as={ChevronDownIcon} />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Stack
                          spacing={0}
                          paddingTop="19px"
                          paddingBottom="19px"
                          maxHeight="35%"
                        >
                          <Text margin="0 auto">Probabilidad de salir:</Text>
                        </Stack>
                        <SimpleGrid columns={3} display="grid">
                          <Box
                            textAlign="right"
                            minWidth="25%"
                            mr="auto"
                            display="flex"
                          >
                            <Text marginTop="11%" width="100%">
                              Baja
                            </Text>
                          </Box>
                          <Box minWidth="237%" ml="-71%">
                            {isToggleSlider && (
                              <Box pt={6} pb={2}>
                                <Slider
                                  aria-label="slider-ex-6"
                                  colorScheme="pink"
                                  defaultValue={50}
                                  ref={sliderInput}
                                  onChange={val => setSliderValue(val)}
                                >
                                  <SliderMark value={25} {...labelStyles}>
                                    25%
                                  </SliderMark>
                                  <SliderMark value={50} {...labelStyles}>
                                    50%
                                  </SliderMark>
                                  <SliderMark value={75} {...labelStyles}>
                                    75%
                                  </SliderMark>
                                  <SliderMark
                                    value={sliderValue}
                                    textAlign="center"
                                    bg="pink.500"
                                    color="white"
                                    mt="-10"
                                    ml="-5"
                                    w="12"
                                  >
                                    {sliderValue}%
                                  </SliderMark>
                                  <SliderTrack>
                                    <SliderFilledTrack />
                                  </SliderTrack>
                                  <SliderThumb boxSize={6}>🍀</SliderThumb>
                                </Slider>
                              </Box>
                            )}
                          </Box>
                          <Box minWidth="25%" mr={0} ml="auto" display="flex">
                            <Text marginTop={'11%'}>Alta</Text>
                          </Box>
                        </SimpleGrid>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
              </Box>
            </Flex>
          ) : (
            ''
          )}
        </Stack>
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          mt="4"
          onClose={() => setIsOpen(false)}
          isClosable
          display={isOpen ? 'flex' : 'none'}
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Ingresa el codigo que te enviamos al mail
          </AlertTitle>

          <>
            <Button
              className={'conectarMP'}
              size="lg"
              color="white"
              style={{ backgroundColor: '#009ee3' }}
              onClick={() => {
                handleRelogin();
              }}
            >
              INGRESAR CODIGO
            </Button>
          </>
          <CloseButton
            onClick={() => setIsOpen(false)}
            position="absolute"
            right="8px"
            top="8px"
          />
        </Alert>
        <Stack spacing={0} padding="31px 11px" maxHeight="35%">
          {token != null ? (
            <SimpleGrid columns={1} spacingX={1} spacingY={1} width="100%">
              <Button
                variant="solid"
                size="md"
                width="100%"
                colorScheme={'pink'}
                onClick={() => {
                  handleSiguiente();
                }}
                isLoading={unanueva ? true : postLoading}
                loadingText={unanueva ? '⏳...' : 'Enviando...'}
              >
                Agregar Premio
              </Button>
              {sessionStorage.public_key &&
                sessionStorage.premioshash &&
                sessionStorage.premioshash.split(',').length > 4 &&
                sessionStorage.premioshash !== '' && (
                  <SimpleGrid
                    columns={
                      getLink &&
                      sessionStorage.precio !== undefined &&
                      sessionStorage.precio !== 'ok' &&
                      sessionStorage.precio.split('|')[0] !== '-'
                        ? 2
                        : 1
                    }
                    spacingX={1}
                    spacingY={1}
                    width="100%"
                  >
                    {getLink &&
                      sessionStorage.precio !== undefined &&
                      sessionStorage.precio !== 'ok' &&
                      sessionStorage.precio.split('|')[0] !== '-' && (
                        <Button
                          width="100%"
                          maxWidth="391px"
                          onClick={() => {
                            handleMostrarLink();
                          }}
                        >
                          <CgLink />
                          Obtener link
                        </Button>
                      )}
                    <Button
                      variant="solid"
                      size="md"
                      width="100%"
                      onClick={() => {
                        window.location.href =
                          'https://putiruleta.com/preview/' +
                          sessionStorage.ruletahash +
                          '/' +
                          btoa(sessionStorage.public_key.slice(0, 15));
                      }}
                      isLoading={unanueva ? true : postLoading}
                    >
                      <AiFillEye />
                      <Text ml="3px"> Previsualizar</Text>
                    </Button>
                  </SimpleGrid>
                )}
              {sessionStorage.premioshash &&
                sessionStorage.premioshash.split(',').length > 0 &&
                sessionStorage.premioshash !== '' && (
                  <Button
                    isLoading={unanueva ? true : postLoading}
                    onClick={handleNueva}
                  >
                    <UsersIcon />
                    <Text>Comenzar de nuevo.</Text>
                  </Button>
                )}
            </SimpleGrid>
          ) : (
            ''
          )}
          <Accordion allowToggle paddingTop="31px">
            {token != null ? (
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      💸 Fijar Precio y mas...
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex mb="auto" flexDirection="column" mt="0">
                    <HStack paddingTop={'21px'}>
                      <Center py={'13px'}>
                        <Text>Precio Actual:</Text>
                      </Center>
                      <Center>
                        <Text
                          fontSize={{
                            base: '19px',
                            md: '21px',
                            lg: '23px',
                          }}
                          color={'green.500'}
                        >
                          {sessionStorage.precio != null
                            ? sessionStorage.precio.split('|')[0] + ' USD'
                            : '-'}
                        </Text>
                      </Center>
                    </HStack>
                    <Box>
                      <Input
                        ref={precioInput}
                        type="email"
                        mt="9px"
                        placeholder={'Cuenta @Paypal'}
                        onChange={handlePaypalChange}
                      />
                    </Box>
                    <Box>
                      <Input
                        ref={precioInput}
                        type="number"
                        mt="9px"
                        placeholder={'Precio en USD'}
                        onChange={handlePrecioChange}
                      />
                    </Box>

                    <Button
                      size="lg"
                      minWidth="100%"
                      marginTop="39px"
                      colorScheme="green"
                      isLoading={unanueva ? true : postLoading}
                      onClick={() => {
                        handleEnviadata();
                      }}
                    >
                      {'Enviar'}
                    </Button>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            ) : (
              <AccordionItem>
                <h2>
                  <AccordionButton ref={acordionItemCuenta}>
                    <Box
                      ref={crearcuentaRef}
                      as="span"
                      flex="1"
                      textAlign="center"
                      className={
                        isFocused ? 'animate__animated animate__heartBeat' : ''
                      }
                    >
                      🔐 Crear Cuenta
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex mb="auto" flexDirection="column" mt="0">
                    <Box>
                      <Input
                        ref={precioInput}
                        type="email"
                        mt="9px"
                        placeholder={'Email @'}
                        onChange={handlePaypalChange}
                      />
                    </Box>
                    <Box>
                      <InputGroup>
                        <Input
                          pr="4.5rem" // Espacio para el ícono del botón
                          type={showPassword ? 'text' : 'password'}
                          type="password"
                          mt="9px"
                          value={input1}
                          disable={true}
                          placeholder={'Password'}
                          onChange={handleInput1Change}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={handleTogglePassword}
                          >
                            {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <InputGroup>
                        <Input
                          pr="4.5rem" // Espacio para el ícono del botón
                          type={showPassword ? 'text' : 'password'}
                          style={
                            bluerd && match
                              ? { borderColor: 'green' }
                              : { borderColor: 'red' }
                          }
                          type="password"
                          mt="9px"
                          value={input2}
                          disabled={true}
                          placeholder={'Repite la Contraseña'}
                          onBlur={handleInput2Blur}
                          onChange={handleInput2Change}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            disabled={true}
                            onClick={handleTogglePassword}
                          >
                            {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                    {bluerd &&
                      (match ? (
                        <p style={{ color: 'green' }}>Perfect!</p>
                      ) : (
                        <p style={{ color: 'red' }}>Error!</p>
                      ))}
                    <Button
                      size="lg"
                      minWidth="100%"
                      marginTop="39px"
                      colorScheme="blue"
                      isLoading={unanueva ? true : postLoading}
                      onClick={() => {
                        handleRegistrarse();
                      }}
                    >
                      {'Crear Cuenta'}
                    </Button>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>
        </Stack>
      </Box>
      <Footer />
    </ChakraProvider>
  );
};
