import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  ButtonGroup,
  VStack,
} from '@chakra-ui/react';
import { FaCopy } from 'react-icons/fa';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import axios from 'axios';
import { useEffect } from 'react';
import { CgLink } from 'react-icons/cg';
import { IoMdRefresh, IoMdArrowRoundBack } from 'react-icons/io';
import useAxios from '../hooks/useAxios';

export default NiceModal.create(props => {
  const dataRuleta = [
    'Minutos de Video Chat',
    'Sexting',
    'Un Pack Fotos',
    'Un Pack videos',
    'DickRate',
  ];
  const modal = useModal();
  const [copied, setCopied] = useState(false);
  const handleEntrarClick = () => {};
  const { width, height } = useWindowSize();
  const [isRaining, setIsRaining] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [terminar, setTerminar] = useState(false);
  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/gotBuyerEmail/' + props.payment_id,
  });

  useEffect(() => {
    if (sessionStorage.precio?.split('|')[2] === sessionStorage.ruletahash)
      setTerminar(true);
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  const handleMostrarLink = () => {
    NiceModal.show('infomodal', {
      link_inv: 'https://putiruleta.com/' + sessionStorage.ruletahash,
    });
    modal.hide();
  };

  const handleRedirect = () => {
    setClicked(true);

    window.location.href =
      'https://paypal.putiruleta.com/descarga/?payment_id=' +
      props.payment_id +
      '&ruleta=' +
      sessionStorage.ruletahash;
  };

  const [input, setInput] = useState('');

  const handleInputChange = e => setInput(e.target.value);

  const guardarMail = (mail = '') => {
    axios.post('https://server-mb9m.onrender.com/postMailBuyer', {
      //axios.post('https://server-mb9m.onrender.com/guardar-mail', {
      email: mail !== '' ? mail : input,
      payment_id: props.payment_id,
    });
  };
  const isError = input === '';

  const [needEmail, setNeedEmail] = useState(true);

  useEffect(() => {
    console.log('LAS PROPS', props); //fonko
    if (response !== null) {
      if (response !== 'nomail') {
        sessionStorage.mail = response;
        setNeedEmail(false);
      }
      if (response === 'nomail') {
        if (sessionStorage.mail !== undefined) {
          guardarMail(sessionStorage.mail);
          setNeedEmail(false);
        }
      }
    }
  }, []);

  return (
    <>
      {isRaining && (
        <Confetti
          colors={['#E098C8', '#76603B', '#B65494', '#66646A']}
          width={width}
          height={height}
          numberOfPieces={200}
        />
      )}
      <Modal
        id="ruletapremio"
        size="sm"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => {
          setIsRaining(false);
          modal.hide();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={3}>
            <Box>
              <Center>
                {!copied ? (
                  <Box>
                    <VStack>
                      <Text
                        fontWeight={'700'}
                        fontSize={{ base: '2x1', md: '3x1', lg: '4x1' }}
                      >
                        Ganaste
                      </Text>
                      <Text
                        fontWeight={'700'}
                        fontSize={{ base: '3em', md: '3em', lg: '5x1' }}
                        style={{
                          textAlign: 'center',
                          maxWidth: '79%',
                          margin: '0 auto',
                        }}
                      >
                        ¡{JSON.parse(sessionStorage.prize).titulo}!
                      </Text>
                      {needEmail && !sessionStorage.preview ? (
                        <FormControl isInvalid={isError}>
                          <FormLabel>Email</FormLabel>
                          <ButtonGroup isAttached variant="outline">
                            <Input
                              type="email"
                              value={input}
                              onChange={handleInputChange}
                            />
                            <Button
                              colorScheme={'pink'}
                              bg="pink.500"
                              color="white"
                              fontSize={{
                                base: '0.8em',
                                sm: '0.8em',
                                md: '0.8em',
                                lg: '0.8em',
                              }}
                              _hover={{ bg: '#e14cbf' }}
                              onClick={() => {
                                guardarMail();
                                setNeedEmail(false);
                              }}
                            >
                              Continuar
                            </Button>
                          </ButtonGroup>
                          {!isError ? (
                            <FormHelperText>
                              Ingresa tu email para continuar.
                            </FormHelperText>
                          ) : (
                            <FormErrorMessage>Obligatorio.</FormErrorMessage>
                          )}
                        </FormControl>
                      ) : JSON.parse(sessionStorage.prize).descarga === 0 ? (
                        <>
                          <Text>
                            Reclama tu premio con el siguiente codigo{' '}
                          </Text>
                          <Alert
                            style={{ marginTop: '45px' }}
                            status="info"
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                            width={'400px'}
                          >
                            <FaCopy
                              onClick={() => {
                                navigator.clipboard.writeText(props.payment_id);
                                setCopied(true);
                                setTimeout(() => {
                                  setCopied(false);
                                }, 3000);
                              }}
                            />
                            <Box
                              paddingLeft={5}
                              onClick={() => {
                                navigator.clipboard.writeText(props.payment_id);
                                setCopied(true);
                                setTimeout(() => {
                                  setCopied(false);
                                }, 3000);
                              }}
                            >
                              {props.payment_id}
                            </Box>
                          </Alert>
                          <Text mt={'15px'} p={'15px'}>
                            <small>
                              Codigo unico para reclamar tu premio con el/la
                              creador/a de contenido.
                            </small>
                          </Text>
                        </>
                      ) : (
                        <Center style={{ paddingTop: '50px' }}>
                          <Button
                            rounded={'full'}
                            px={6}
                            colorScheme={'pink'}
                            size="40px"
                            bg="pink.500"
                            color="white"
                            _hover={{ bg: '#e14cbf' }}
                            style={{
                              width: '300px',
                              height: '70px',
                            }}
                            isLoading={clicked}
                            loadingText="Descargando"
                            onClick={() => {
                              handleRedirect();
                            }}
                          >
                            Descargar
                          </Button>
                        </Center>
                      )}
                    </VStack>
                  </Box>
                ) : (
                  <>
                    <Box>
                      <VStack>
                        <Text
                          fontWeight={'700'}
                          fontSize={{ base: '2x1', md: '3x1', lg: '4x1' }}
                        >
                          Ganaste
                        </Text>
                        <Text
                          fontWeight={'700'}
                          fontSize={{ base: '3em', md: '3em', lg: '5x1' }}
                          style={{
                            textAlign: 'center',
                            maxWidth: '79%',
                            margin: '0 auto',
                          }}
                        >
                          ¡{JSON.parse(sessionStorage.prize).titulo}!
                        </Text>
                        <Text></Text>
                      </VStack>
                      <Alert
                        marginTop={'45px'}
                        status="success"
                        justifyContent="center"
                        width={'400px'}
                      >
                        <AlertIcon />
                        <Box>Copiado!</Box>
                      </Alert>
                      <Text mt={'15px'} p={'15px'}>
                        <small>
                          Codigo unico para reclamar tu premio con el/la
                          creador/a de contenido.
                        </small>
                      </Text>
                    </Box>
                  </>
                )}
              </Center>
            </Box>

            {sessionStorage.preview && (
              <>
                <Center m={'13px 0'}>
                  <Button
                    width="100%"
                    maxWidth="391px"
                    onClick={() => {
                      window.location.reload(false)();
                    }}
                  >
                    <IoMdRefresh />
                    Recargar
                  </Button>
                </Center>
                <Center m={'13px 0'}>
                  <Button
                    width="100%"
                    maxWidth="391px"
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    <IoMdArrowRoundBack />
                    Volver
                  </Button>
                </Center>
                {terminar && (
                  <Center m={'13px 0'}>
                    <Button
                      width="100%"
                      maxWidth="391px"
                      onClick={() => {
                        handleMostrarLink();
                      }}
                    >
                      <CgLink />
                      Obtener link
                    </Button>
                  </Center>
                )}
                <Center m={'13px 0'}>
                  <small>
                    Recarga la pagina si quieres testear las probabilidades.
                  </small>
                </Center>
              </>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
