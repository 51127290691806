import { useState, useEffect, useRef } from 'react';

import { Wheel } from 'react-custom-roulette';

import { Button, VStack, Box } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';
import React from 'react';
import axios from 'axios';
import { ReactComponent as MercadoPagoIcon } from './mpagos.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaPaypal } from 'react-icons/fa';
import 'animate.css';

const CustomWheel = ({
  elprecio,
  datos,
  urlPagos,
  ob_premio = {},
  payment_id = '',
}) => {
  const [pais, setPais] = useState();
  const [opciones, setOpcoines] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const [ruleta, setRuleta] = useState();
  const [isPaid, setIsPaid] = useState(false);
  const [isIdle, setIsIdle] = useState(false);
  const [daleputo, setDaleputo] = useState(false);
  const [precio, setPrecio] = useState(elprecio);
  const [mustSpin, setMustSpin] = useState(false);
  const [ruletalocal, setLocal] = useState();
  const [ruletaextranjera, setExtranjero] = useState();
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [userActivityInterval, setUserActivityInterval] = useState(null);
  const [handleUserActivity, setHandleUserActivity] = useState(null);
  const botonRef = useRef(null);

  const [
    { data: getDataPago, loading: getLoadingPago, error: getErrorPago },
    refetchPago,
  ] = useAxios(
    {
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url:
        'https://server-mb9m.onrender.com/getProcesarPP/' + sessionStorage.code,
      useCache: false,
    },
    { manual: true }
  );

  /*var dataRuleta = [
    {
      option: 'Video Chat ' + props.minChat,
      style: { textColor: 'black', backgroundColor: '#EDBD50' }, // 1%
    },
    { option: 'Sexting ' + props.minSext }, //60%
    { option: 'Pack fotos' }, // 70%
    { option: 'Pack videos' }, //60%
    { option: 'Dick Rate' }, //75%
  ];*/

  useEffect(() => {
    //  console.log(ob_premio, 'url pagos', urlPagos, payment_id);

    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then(response => {
        sessionStorage.pais = response.data.country_code;
        setPais(response.data.country_code);
      })
      .catch(error => {
        console.log(error);
      });

    setPais(sessionStorage.pais);

    function handleUserActivity() {
      if (!userActivityInterval) {
        const id = setInterval(() => {
          setIsIdle(true);
          setTimeout(() => {
            setIsIdle(false);
          }, 2000);
        }, 5000);

        setUserActivityInterval(id);
      }
    }

    // Escuchar eventos de actividad del usuario en todo el documento
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    setHandleUserActivity(handleUserActivity);
    return () => {
      // Limpiar eventos cuando se desmonta el componente
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearInterval(userActivityInterval);
    };

    //console.log('el pais: ', sessionStorage.pais);
  }, []);

  const backgroundColors = ['#EED9F2', '#343036', '#B65796'];
  const textColors = ['#B65796', '#EDBD50', '#EED9F2'];
  const outerBorderColor = '#eeeeee';
  const outerBorderWidth = 15;
  const innerBorderColor = '#30261a';
  const innerBorderWidth = 5;
  const innerRadius = 20;
  const radiusLineColor = '#30261a';
  const radiusLineWidth = 8;
  const fontSize = 13;
  const textDistance = 60;
  const spinDuration = 1.0;

  function safeJsonParse(str) {
    try {
      return [null, JSON.parse(str)];
    } catch (err) {
      return [err];
    }
  }
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  useEffect(() => {
    if (query.get('token') && !query.get('cancel')) {
      sessionStorage.setItem('code', query.get('token'));
      setIsLoading(true);
      setDisable(true);
      console.log('asdñlfkjasld fasñld fañsldfkj asñld f');
      axios
        .get(
          'https://server-mb9m.onrender.com/success?PayerID=' +
            query.get('PayerID') +
            '&paymentId=' +
            query.get('token')
        )
        .then(res => {
          if (res.status === 200) {
            const datos = res.data;
            console.log(datos);
            sessionStorage.setItem('titulo', datos.prize.titulo);
            sessionStorage.setItem('prize', JSON.stringify(res.data.prize));

            setIsLoading(false);
            setDisable(false);
            setIsPaid(true);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (query.get('token') && !query.get('cancel')) {
      sessionStorage.setItem('code', query.get('token'));
    }
    if (sessionStorage.prize) {
      const prizeNumber = JSON.parse(sessionStorage.prize).index;
      console.log(prizeNumber);
      setPrizeNumber(prizeNumber);
    }
    //console.log(prize);
    for (let i = 0; i < datos.length; i++) {
      datos[i].option =
        datos[i].option.length > 20
          ? datos[i].option.substring(0, 20) + '...'
          : datos[i].option;
    }
    setRuleta(datos);
    axios.get('/precio/' + sessionStorage.ruletahash).then(res => {
      setPrecio(res.data.precio);
      sessionStorage.setItem('precio', res.data.precio);
    });

    //console.log(sessionStorage.premio);
  }, []);

  useEffect(() => {
    if (isPaid) {
      setDisable(false);
      setIsLoading(false);
    }
  }, [isPaid]);

  const handleSpinClick = () => {
    setIsLoading(true);
    setDisable(true);
    if (urlPagos === 'preview') {
      NiceModal.show('infomodalpreview');
      setIsPaid(true);
    } else {
      //console.log(urlPagos);

      let data = {};
      data.ruleta = sessionStorage.ruletahash;
      axios
        .post('https://server-mb9m.onrender.com/pay/', { data })
        .then(res => {
          console.log(res);
          setDisable(true);
          window.location.href = res.data.url;
        });
    }
  };

  const handlePaidSpinClick = () => {
    console.log(
      elprecio,
      datos,
      urlPagos,
      sessionStorage.objetopremio,
      payment_id
    );
    setDaleputo(true);
    setPrizeNumber(sessionStorage.prizeNumber);

    document.removeEventListener('mousemove', handleUserActivity);
    document.removeEventListener('keydown', handleUserActivity);
    setHandleUserActivity(null);
    botonRef.current.classList.remove('animate__heartBeat');
    botonRef.current.classList.remove('animate__animated');
    setMustSpin(true);
    setDisable(true);
  };

  function formatPrice(price) {
    const decimalPart = (price % 1).toFixed(2).substring(2);

    if (isNaN(decimalPart)) {
      setTimeout(() => {
        return formatPrice(price); // Recargar la función con el mismo valor
      }, 2000);
    }

    return (
      <>
        {parseInt(price).toFixed(0)}.
        <span style={{ verticalAlign: 'text-top', fontSize: '13px' }}>
          {decimalPart}
        </span>
      </>
    );
  }

  return (
    <VStack spacing={3}>
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={
          sessionStorage.prize != null
            ? JSON.parse(sessionStorage.prize).index
            : null
        }
        data={datos}
        backgroundColors={backgroundColors}
        textColors={textColors}
        fontSize={fontSize}
        outerBorderColor={outerBorderColor}
        outerBorderWidth={outerBorderWidth}
        innerRadius={innerRadius}
        innerBorderColor={innerBorderColor}
        innerBorderWidth={innerBorderWidth}
        radiusLineColor={radiusLineColor}
        radiusLineWidth={radiusLineWidth}
        spinDuration={spinDuration}
        // perpendicularText
        textDistance={textDistance}
        onStopSpinning={() => {
          setTimeout(() => {
            payment_id =
              sessionStorage.code !== ''
                ? sessionStorage.code
                : btoa(sessionStorage.public_key.slice(0, 9));
            NiceModal.show('ruletapremio', {
              ob_premio: sessionStorage.objetopremio,
              payment_id: sessionStorage.code,
              terminar: sessionStorage.urlPago ? true : false,
            });
          }, 1000);
          setMustSpin(false);
        }}
      />
      {!daleputo ? (
        <Button
          className={
            'animate__animated ' +
            (isPaid ? (isIdle ? ' animate__heartBeat' : '') : '')
          }
          disabled={disable}
          isLoading={isLoading}
          loadingText="⌛..."
          backgroundColor={'#EED9F2'}
          ref={botonRef}
          onClick={isPaid ? handlePaidSpinClick : handleSpinClick}
          size="lg"
          color={'black'}
          px={6}
          colorScheme={'pink'}
          bg={'pink.400'}
          _hover={{ bg: 'pink.500', color: 'white' }}
        >
          {isPaid ? (
            <Box>Jugar ⚡️</Box>
          ) : precio !== null ? (
            <>
              <Box style={{ paddingLeft: '15px' }}>
                <FaPaypal style={{ width: '25px', height: '25px' }} />
              </Box>
              <Box
                style={{
                  paddingLeft: '15px',
                  paddingRight: '2px',
                  fontSize: '13px',
                }}
              >
                {'USD'}
              </Box>
              <Box>{formatPrice(sessionStorage.precioPaypal)}</Box>
            </>
          ) : (
            <Box>Cargando...</Box>
          )}
        </Button>
      ) : (
        <Button disabled={true}>⏳</Button>
      )}
      {isPaid ? (
        <> </>
      ) : (
        <VStack>
          <small style={{ fontWeight: 700 }}>(Dolares Americanos.)</small>
        </VStack>
      )}
    </VStack>
  );
};

export default CustomWheel;
