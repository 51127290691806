import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Box, Heading, Text, VStack, Center, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';

function DescargaIndividual() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const paymentId = query.get('payment_id');
  console.log(paymentId);
  // Obtener payment_id y metadata de los parámetros de la URL

  // Realizar la solicitud HTTP POST al endpoint en PHP
  const sendRequestToPHP = async () => {
    try {
      const response = await axios.post(
        'https://www.putiruleta.com/descarga/individual.php',
        {
          paymentId,
        }
      );
      console.log('Respuesta del servidor PHP:', response.data);
      // Iniciar la descarga utilizando navigate() si es necesario
      window.location.href =
        'https://paypal.putiruleta.com/descarga/' + response.data.substring(6);
    } catch (error) {
      console.log(error);
      // Manejar el error de la solicitud al servidor PHP si es necesario
    }
  };

  // Llamar a la función para enviar la solicitud al servidor PHP
  sendRequestToPHP();

  // Renderizar la página de éxito o redirigir a otra página si es necesario
  return (
    <Center
      h="100vh"
      w="100vw"
      style={{ backgroundImage: "url('fondo_kawaii_putiruleta4.jpg')" }}
    >
      <VStack spacing={4} style={{ backgroundColor: 'white' }}>
        <Heading as="h1" size="2xl">
          Pago exitoso
        </Heading>
        <Text>Iniciando Descarga</Text>
        <Spinner
          thickness="24px"
          speed="0.65s"
          emptyColor="#a5e0cf"
          color="#f5a7d5"
          style={{
            position: 'relative',
            zIndex: 7,
            width: '151px',
            height: '151px',
            margin: '37% auto',
            transform: 'translate( -50%, -50% )',
          }}
        />
        <Text>Soporte tecnico: hola@putiruleta.com</Text>
      </VStack>
    </Center>
  );
}

export default DescargaIndividual;
