import React, { useEffect, useState, useRef } from 'react';
import {
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';

export default function TagPremio({ titulo = '', hash, onEvent }) {
  const [cerrar, setCerrar] = useState(false);
  const tag = useRef(null);
  const [
    { data: postData, loading: postLoading, error: postError },
    executePost,
  ] = useAxios(
    {
      url: 'https://server-mb9m.onrender.com/delPremio',
      method: 'POST',
    },
    { manual: true }
  );
  let data = {};
  const handleClose = () => {
    data.hash = hash;
    data.key = sessionStorage.public_key;
    data.ruleta_hash = sessionStorage.ruletahash;
    // console.log(data);
    //console.log('asdfasdf');
    executePost({ data }).then(() => {
      onEvent();
      setCerrar(true);
    });
  };

  if (!cerrar) {
    return (
      <Tag
        size={['sm', 'md', 'lg']}
        display={'inline-block'}
        borderRadius="2"
        variant="solid"
        thickness="4px"
        speed="0.65s"
        opacity={1}
        hash={hash}
        key={hash}
        ref={tag}
        width="100%"
        style={{ backgroundColor: 'black' }}
        minWidth="113px"
      >
        <HStack m={'9px auto'} justifyContent={'center'}>
          {postLoading ? (
            <Spinner size="xs" />
          ) : (
            <>
              <TagLabel>
                <small>{titulo}</small>
              </TagLabel>
              <TagCloseButton
                size={['sm', 'md', 'lg']}
                onClick={() => {
                  handleClose();
                }}
              />
            </>
          )}
        </HStack>
      </Tag>
    );
  }
}
