import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Integracion from './Integracion';
import Auth from './Auth';
import Preview from './Preview';
import Home from './Home';
import { JustAPanel } from './JustAPanel';
import { Box, Text, Link, VStack, Code, Grid } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import useAxios from 'axios-hooks';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import InfoModal from './InfoModal';
import QueOndaPuto from './QueOndaPuto';
import DescargaIndividual from './DescargaIndividual';
import DescargaTest from './DescargaIndividual';

import axios from 'axios';

NiceModal.register('infomodal', InfoModal);

export default function AnimatedRouters() {
  const [pais, setPais] = useState();
  const [image, setImage] = useState();

  const createHash = () => {
    const hash = Array.from(Array(6), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join('');

    return hash;
  };

  const location = useLocation();

  if (!pais) {
    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then(response => {
        sessionStorage.pais = response.data.country_code;
        setPais(response.data.country_code);
      })
      .catch(error => {
        console.log(error);
      });
    return <>Loading</>;
  }

  return (
    <AnimatePresence>
      <NiceModal.Provider>
        <Routes location={location} key={location.path}>
          <Route path="/:hash/:creadora" element={<Auth />} />
          <Route path="/:hash" element={<Home pais={pais} image={image} />} />
          <Route path="/preview/:hash/:idcheck" element={<Preview />} />
          <Route
            path="/descarga-individual/:hash/"
            element={<DescargaIndividual />}
          />
          <Route
            path="/descarga-individual-test/:hash/:idcheck"
            element={<DescargaTest />}
          />

          <Route path="/" element={<JustAPanel />} />
        </Routes>
      </NiceModal.Provider>
    </AnimatePresence>
  );
}
