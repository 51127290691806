import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { Helmet } from 'react-helmet';
import {
  ChakraProvider,
  extendTheme,
  Box,
  Checkbox,
  Stack,
  Text,
  Heading,
  Select,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Spinner,
  VStack,
  Grid,
  Button,
  Container,
  Input,
  Flex,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Center,
  WrapItem,
  Wrap,
} from '@chakra-ui/react';
import CustomWheel from './CustomWheel';

import TagPremio from './TagPremio';
import PreviewContent from './PreviewContent';
import Footer from './Footer';
import axios from 'axios';
import NavBarRuleta from './NavBarRuleta';

export default function Home({ pais, image }) {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const [thumbs, setThumbs] = useState();

  const [urlPagos, setUrlPagos] = useState();
  let params = useParams();
  const [{ data: getData, loading: getLoading, error: getError }, refetch] =
    useAxios({
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url: 'https://server-mb9m.onrender.com/getPremios/' + params.hash,
      useCache: false,
    });

  const [
    { data: getDataRuleta, loading: getLoadingRuleta, error: getErrorRuleta },
    refetchRuleta,
  ] = useAxios({
    //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
    url: 'https://server-mb9m.onrender.com/getPutiruleta/' + params.hash,
    useCache: false,
  });

  const [
    { data: getDataPago, loading: getLoadingPago, error: getErrorPago },
    refetchPago,
  ] = useAxios(
    {
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url:
        'https://server-mb9m.onrender.com/getProcesarPP/' + query.get('token'),
      useCache: false,
    },
    { manual: true }
  );

  const [prizeNumber, setPrizeNumber] = useState(null);
  const [dataRuleta, setDataRuleta] = useState([]);
  const [premio, setPremio] = useState(null);
  const [isCreadoraViewing, setIsCreadoraViewing] = useState(false);
  const [paymentid, setPaymentID] = useState();

  const theme = extendTheme({
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          fontFamily: "'Poppins', sans-serif;",
        },
      }),
    },
  });

  useEffect(() => {
    const tmbs = axios
      .get('https://putiruleta.com/files/scandir.php?hash=' + params.hash)
      .then(res => {
        var thmbs = res.data?.map((item, i) => {
          if (i > 1) {
            return params.hash + '/' + item;
          }
        });
        setThumbs(thmbs.filter(x => x !== undefined));
      });
  }, []);

  function isJson(item) {
    item = typeof item !== 'string' ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === 'object' && item !== null) {
      return true;
    }

    return false;
  }

  function safeJsonParse(str) {
    try {
      return [null, JSON.parse(str)];
    } catch (err) {
      return [err];
    }
  }

  var arry_test = [];
  const [precio, setPrecio] = useState();
  useEffect(() => {
    sessionStorage.setItem('ruletahash', params.hash);
    try {
      if (getDataRuleta !== undefined) {
        setUrlPagos(getDataRuleta.resultado[0].url_pago);
        sessionStorage.setItem('pais_ruleta', getDataRuleta.resultado[0].pais);

        if (getDataRuleta.resultado[0].precio.split('|').length > 0) {
          const numeros = getDataRuleta.resultado[0].precio
            .split('|')
            .map(Number);
          const valorMasPequeno = Math.min(...numeros);
          const precioFinal = valorMasPequeno / 38 + 2;
          setPrecio(precioFinal.toFixed(2));
          sessionStorage.precioPaypal = precioFinal.toFixed(2);
          console.log(precioFinal.toFixed(2));
        } else {
          setPrecio(getDataRuleta.resultado[0].precio);
          sessionStorage.precio = getDataRuleta.resultado[0].precio;
        }
      }
    } catch (getErrorRuleta) {
      console.log(getErrorRuleta);
    }
    try {
      if (getData !== undefined) {
        arry_test = [];
        var temp = getData.resultado.map(item => {
          arry_test.push({
            option:
              item.titulo.length > 20
                ? item.titulo.substring(0, 20) + '...'
                : item.titulo,
          });
          return item;
        });

        setDataRuleta(arry_test);
        console.log('array test', arry_test, 'temp', temp);
      } else {
        //refetch();
      }
    } catch (getError) {
      console.log(getError);
    }
  }, [getData, getDataRuleta]);

  const navigate = useNavigate();

  if (getLoading || getLoadingPago || getLoadingRuleta)
    return (
      <ChakraProvider theme={theme}>
        <Container width="80vw" minWidth="60vw">
          <Stack spacing={2} minHeight="20%">
            <Spinner
              thickness="24px"
              speed="0.65s"
              emptyColor="#a5e0cf"
              color="#f5a7d5"
              style={{
                position: 'relative',
                zIndex: 7,
                width: '151px',
                height: '151px',
                margin: '37% auto',
                transform: 'translate( -50%, -50% )',
              }}
            />
          </Stack>
        </Container>
      </ChakraProvider>
    );
  if (getError || getErrorRuleta) return <p>Error! {getError.message}</p>;

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Los Premios de la Putiruleta!" />
        <meta
          property="og:description"
          content={'Fotos, Videos, Sexting, Video Chat, Zing y mas'}
        />
        <meta property="og:image" content={image} />
      </Helmet>
      <ChakraProvider theme={theme}>
        <NavBarRuleta ruleta={params.hash} />
        <Flex
          style={{
            backgroundImage: "url('../../fondo_kawaii_putiruleta4.jpg')",
            minWidth: '100%',
          }}
        >
          <Container m={0} width="100vw" minWidth="100%">
            <Box>
              <CustomWheel
                datos={dataRuleta}
                precio={precio}
                urlPagos={urlPagos}
                ob_premio={
                  premio && {
                    titulo: premio.titulo && premio.titulo,
                    index: premio.index && premio.index,
                    metadata: premio.hash && premio.hash,
                    descarga: premio.descarga && premio.descarga,
                  }
                }
                prize={prizeNumber && prizeNumber}
                payment_id={query.get('token') ? query.get('token') : null}
              />
            </Box>
            <Center m="31px auto">
              <Checkbox colorScheme="green" defaultChecked>
                Soy mayor de edad en mi pais de recidencia.
              </Checkbox>
            </Center>

            <Divider borderColor="blackAlpha.500" />
            {thumbs && (
              <Box>
                <Center m={'13px 0'}>
                  <Heading size="md">Premios:</Heading>
                </Center>
                <Box>
                  <PreviewContent
                    minHeigth="250px"
                    thumbs={thumbs}
                    ruleta={params.hash}
                  />
                </Box>
              </Box>
            )}
            <Footer />
          </Container>
        </Flex>
      </ChakraProvider>
    </>
  );
}
